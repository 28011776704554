import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./NotFound.module.scss"

class NotFound extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className={css.container}>
        <div className={css.wrapper}>
          <h1>404 - Not Found</h1>
          <p>Gonna need to strap up that boot properly next time.</p>
        </div>
      </div>
    )
  }
}
export default NotFound;
